<template>
  <div class="BIMCAD-highlight">
    <div
      class="BIMCAD-highlight__content__title m-auto col-11 col-sm-10 text-center"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      {{ title }}
    </div>
    <div class="d-flex flex-column">
      <div
        class="d-flex col-sm-10 m-auto BIMCAD-highlight__item-card"
        style="gap: 18px"
      >
        <div
          style="flex: 1 1 0%"
          class="m-auto"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
        >
          <img class="w-100" v-lazy="bimcad_1" alt="bimcad_1" />
        </div>
        <div
          style="flex: 1 1 0%"
          class="m-auto"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
        >
          <div
            class="d-flex flex-column w-100 align-content-center pl-sm-3 pr-sm-5"
            style="gap: 16px"
          >
            <div
              class="bimcad_title"
              style="color: #373737; font-size: 32px; font-weight: 700"
            >
              {{ content1.title }}
            </div>
            <div class="d-none d-sm-flex BIMCAD-highlight-content">
              {{ content1.sub }}
            </div>
            <button
              type="button"
              class="btn btn-primary text see-all d-flex align-items-center btn-CAD-AI"
              data-toggle="modal"
              data-src="https://www.youtube.com/embed/Um098tO_u7c"
              data-target="#myModal"
              @click="
                urlVideo = 'https://www.youtube.com/embed/Um098tO_u7c';
                openModal();
              "
            >
              {{ btnView }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="w-100 item-highlight mt-4 mt-sm-0 BIMCAD-highlight__item-card"
        style="background-color: #fff2eb"
      >
        <div
          class="d-flex flex-row-reverse col-sm-10 m-auto p-0 pl-3 p-sm-0"
          style="gap: 18px"
        >
          <div
            style="flex: 1 1 0%"
            class="m-auto"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-offset="300"
          >
            <img class="w-100" v-lazy="bimcad_2" alt="bimcad_2" />
          </div>
          <div
            style="flex: 1 1 0%"
            class="m-auto"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-offset="300"
          >
            <div
              class="d-flex flex-column w-100 align-content-center pr-sm-3 pl-sm-5 align-items-end text-right"
              style="gap: 16px"
            >
              <div
                class="bimcad_title"
                style="color: #373737; font-size: 32px; font-weight: 700"
              >
                {{ content2.title }}
              </div>
              <div class="d-none d-sm-flex BIMCAD-highlight-content">
                {{ content2.sub }}
              </div>
              <button
                type="button"
                class="btn btn-primary text see-all d-flex align-items-center btn-revit"
                data-toggle="modal"
                data-src="https://www.youtube.com/embed/pRVfYrbQuKU"
                data-target="#myModal"
                @click="
                  urlVideo = 'https://www.youtube.com/embed/pRVfYrbQuKU';
                  openModal();
                "
              >
                {{ btnView }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex col-sm-10 mt-4 mt-sm-0 mx-auto BIMCAD-highlight__item-card"
        style="gap: 18px"
      >
        <div
          style="flex: 1 1 0%"
          class="m-auto"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
        >
          <img class="w-100" v-lazy="bimcad_3" alt="bimcad_3" />
        </div>
        <div
          style="flex: 1 1 0%"
          class="m-auto"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
        >
          <div
            class="d-flex flex-column w-100 align-content-center pl-sm-3 pr-sm-5"
            style="gap: 16px"
          >
            <div
              class="bimcad_title"
              style="color: #373737; font-size: 32px; font-weight: 700"
            >
              {{ content3.title }}
            </div>
            <div class="d-none d-sm-flex BIMCAD-highlight-content">
              {{ content3.sub }}
            </div>
            <a
              href="/ai-house"
              target="_blank"
              rel="noopener noreferrer"
              style="text-decoration: none; text-decoration: none"
            >
              <button
                type="button"
                class="btn btn-primary text see-all d-flex align-items-center btn-CAD-AI"
              >
                {{ content3.button }}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal-video" v-if="isModalOpen">
      <div class="modal-video-content">
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            :src="`${urlVideo}?autoplay=1&amp;modestbranding=1&amp;showinfo=0`"
            id="video"
            allowscriptaccess="always"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BIMCAD_highlight',
  data() {
    return {
      isModalOpen: false,
      title: this.$t('home').BIMCAD_highlight.title,
      sub: this.$t('home').BIMCAD_highlight.sub,
      btnView: this.$t('home').BIMCAD_highlight.button,
      content1: this.$t('home').BIMCAD_highlight.content1,
      content2: this.$t('home').BIMCAD_highlight.content2,
      content3: this.$t('home').BIMCAD_highlight.content3,
      bimcad_1: '/images/home/bimcad_1.webp',
      bimcad_2: '/images/home/bimcad_2.webp',
      bimcad_3: '/images/home/bimcad_3.webp'
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  },
  watch: {
    lang() {
      this.title = this.$t('home').BIMCAD_highlight.title;
      this.sub = this.$t('home').BIMCAD_highlight.sub;
      this.btnView = this.$t('home').BIMCAD_highlight.button;
      this.content1 = this.$t('home').BIMCAD_highlight.content1;
      this.content2 = this.$t('home').BIMCAD_highlight.content2;
      this.content3 = this.$t('home').BIMCAD_highlight.content3;
    }
  },

  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.BIMCAD-highlight {
  width: 100%;
  padding: 80px 0;
  .BIMCAD-highlight__content__title {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    color: var(--blue-text);
    z-index: 0;
    margin-bottom: 80px !important;
  }
  .BIMCAD-highlight-content {
    font-size: 20px;
    font-weight: 500;
    color: var(--grey-text);
  }
  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  .modal-video-content {
    padding: 20px;
    border-radius: 4px;
    position: relative;
    width: 90%;
    max-width: 990px;
  }
  .close {
    position: absolute;
    right: -10px;
    top: 0;
    z-index: 999;
    font-size: 2rem !important;
    font-weight: normal;
    color: #fff !important;
    opacity: 1;
  }

  .btn-CAD-AI {
    background: #fff;
    border-radius: 40px;
    padding: 8px 28px;
    width: max-content;
    border: 1px solid #fc7735;
    color: #fc7735;
    gap: 8px;
  }

  .btn-revit {
    background: transparent;
    border-radius: 40px;
    padding: 8px 28px;
    width: max-content;
    border: 1px solid #fc7735;
    color: #fc7735;
    gap: 8px;
  }

  .btn-CAD-AI:hover,
  .btn-revit:hover {
    opacity: 0.6;
    transition: 0.3s;
  }
  .BIMCAD-highlight__item-card {
    padding: 20px 0;
  }

  @media (max-width: 767.98px) {
    & {
      padding-bottom: 0;
    }
    .bimcad_title {
      font-size: 18px !important;
    }
    .BIMCAD-highlight__content__title {
      font-size: 32px !important;
    }
    img {
      height: 150px;
    }
  }
}
</style>
